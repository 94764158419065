/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import './src/style.css';

// do not mix and match ES6 import/export with require/exports
export const onClientEntry = () => {
  if (window.location.pathname === '/de/') {
    window.location.pathname = '/de/index.html';
  }
  if (window.location.pathname === '/en/') {
    window.location.pathname = '/en/index.html';
  }
};
